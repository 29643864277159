<template>
    <app-layout
        :title="__('Bulk Processing')"
        :view="__('Files')"
        :user="$attrs.user"
    >
        <div class="row gx-5 gx-xl-10 mb-10" v-if="!isFileUploading">
            <ListingHeader @reload-data="reloadData" />
        </div>

        <UploadFile
            @file-upload-error="handleFileUploadError"
            @file-upload-started="fileUploadStarted"
            @file-upload-finished="fileUploadFinished"
        />

        <div class="card card-flush mt-10" v-if="!isFileUploading">
            <!--start::Card body-->
            <div class="card-body p-0">
                <ListingTable
                    :bulkProcessings="bulkProcessings"
                    :isLoading="isLoading"
                    @on-search="searchBulprocessing"
                    @reload-data="reloadData"
                    class="bulk-processing-table p-7"
                />
                <Pagination :pages="pages" v-if="noOfPages > 1">
                    <template #entriesDisplay>
                        {{ paginationEntriesText }}
                    </template>
                </Pagination>
            </div>
            <!--end::Card body-->
        </div>
        <ListingSkeleton v-if="isFileUploading" />
        <FileUploadErrorModal />
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import ListingHeader from '@/Pages/BulkProcessing/Partials/Index/ListingHeader.vue';
import UploadFile from '@/Pages/BulkProcessing/Partials/Index/UploadFile.vue';
import ListingTable from '@/Pages/BulkProcessing/Partials/Index/ListingTable.vue';
import ListingSkeleton from '@/Pages/BulkProcessing/Partials/Index/ListingSkeleton.vue';
import FileUploadErrorModal from '@/Pages/BulkProcessing/Partials/Modals/FileUploadErrorModal.vue';
import Pagination from '@/Components/Pagination/Pagination.vue';
import { usePage } from '@inertiajs/vue3';
import moment from 'moment';
import { router } from '@inertiajs/vue3';

export default defineComponent({
    name: 'BulkProcessingIndex',
    components: {
        AppLayout,
        ListingHeader,
        UploadFile,
        ListingTable,
        ListingSkeleton,
        FileUploadErrorModal,
        Pagination,
    },

    setup() {
        const page = usePage();

        return {
            page,
        };
    },

    data() {
        return {
            bulkProcessingInProgress: [],
            hasFileUploadingError: false,
            isLoading: false,
            isFileUploading: false,
            processingStream: null,
        };
    },

    watch: {
        bulkProcessings: {
            handler(bulkProcessings) {
                this.bulkProcessingInProgress =
                    this.getBulkProcessingInProgress(bulkProcessings);
            },
            immediate: true,
        },
        bulkProcessingInProgress: {
            handler(bulkProcessingInProgress) {
                const vm = this;
                if (bulkProcessingInProgress.length === 0) {
                    return false;
                }
                if (this.processingStream) {
                    return false;
                }
                this.processingStream = new EventSource(
                    // eslint-disable-next-line no-undef
                    route('bulk-processings.processing-status', {
                        uuids: bulkProcessingInProgress,
                    })
                );

                this.processingStream.onmessage = function (event) {
                    const data = JSON.parse(event.data);

                    if (data.length === 0 || data.has_error) {
                        vm.closeProcessingStream();
                        return false;
                    }

                    if (data.length > 0) {
                        data.forEach((processing) => {
                            const bulkProcessing =
                                vm.page.props.bulk_processings.data?.find(
                                    (bulkProcessing) =>
                                        bulkProcessing.uuid === processing.uuid
                                );
                            bulkProcessing.progress = processing.progress;
                        });

                        // Check if all progress values are 100
                        if (
                            data.every(
                                (processing) => processing.progress === 100
                            )
                        ) {
                            vm.closeProcessingStream();
                        }
                    }
                };

                this.processingStream.onerror = function () {
                    vm.closeProcessingStream();
                };
            },
            immediate: true,
        },
    },

    mounted() {
        const vm = this;
        router.on('start', () => {
            vm.closeProcessingStream();
        });

        window.onbeforeunload = () => {
            vm.closeProcessingStream();
        };
    },

    beforeUnmount() {
        this.closeProcessingStream();
    },

    computed: {
        bulkProcessings() {
            const vm = this;

            return this.page.props.bulk_processings?.data?.map(
                (bulkProcessing) => {
                    const progress = bulkProcessing.progress;
                    const status =
                        progress >= 100
                            ? vm.__('Completed')
                            : vm.__('In Progress');
                    const date = moment(bulkProcessing.created_at).format(
                        'DD/MM/YYYY'
                    );
                    const authorName = bulkProcessing?.user?.name || '';

                    return {
                        authorName,
                        date,
                        id: bulkProcessing.id,
                        name: bulkProcessing.name,
                        progress: bulkProcessing.progress,
                        status,
                        uuid: bulkProcessing.uuid,
                    };
                }
            );
        },
        pages() {
            return this.page.props.bulk_processings?.links;
        },
        noOfPages() {
            return this.page.props.bulk_processings?.last_page;
        },
        paginationEntriesText() {
            let { per_page: perPage, total } = this.page.props.bulk_processings;
            perPage = perPage > total ? total : perPage;

            return this.__(`Showing ${perPage} of ${total} entries`);
        },
    },

    methods: {
        getBulkProcessingInProgress(bulkProcessings) {
            return bulkProcessings
                ?.filter((bulkProcessing) => bulkProcessing.progress < 100)
                .map((bulkProcessing) => bulkProcessing.uuid);
        },
        searchBulprocessing(value) {
            this.isLoading = true;
            router.get(
                // eslint-disable-next-line no-undef
                route('bulk-processings.index'),
                {
                    search: value,
                },
                {
                    preserveState: true,
                    onSuccess: () => {
                        this.isLoading = false;
                    },
                }
            );
        },
        reloadData() {
            router.reload();
        },
        handleFileUploadError() {
            this.isFileUploading = false;
            this.hasFileUploadingError = true;
            // eslint-disable-next-line no-undef
            emitter.emit('open-bulk-processing-file-upload-error-modal');
        },
        fileUploadStarted() {
            this.isFileUploading = true;
            this.hasFileUploadingError = false;
        },
        fileUploadFinished(file) {
            if (this.hasFileUploadingError) {
                return false;
            }
            const payload = {
                server_id: file.serverId,
            };
            // eslint-disable-next-line no-undef
            router.get(route('bulk-processings.create'), payload);
        },
        closeProcessingStream() {
            if (!this.processingStream) {
                return false;
            }
            this.processingStream.close();
            this.processingStream = null;
        },
    },
});
</script>

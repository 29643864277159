<template>
    <div class="dataTables_paginate paging_simple_numbers px-7 py-5 border-top">
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <slot name="entriesDisplay"> </slot>
            </div>
            <ul class="pagination">
                <li
                    v-for="(page, index) in pages"
                    :key="index"
                    class="page-item"
                    :class="{
                        'prev previous': index === 0,
                        next: index === pages.length - 1,
                        active: page.active,
                    }"
                >
                    <a
                        href="#"
                        @click.prevent="$emit('on-page-change', page)"
                        class="page-link"
                    >
                        <span v-if="index === 0">
                            <i class="previous"></i>
                        </span>
                        <span v-else-if="index === pages.length - 1">
                            <i class="next"></i>
                        </span>
                        <span v-else>{{ page.label }}</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'DefaultPagination',
    props: {
        pages: {
            type: Array,
            default: () => [],
        },
    },
});
</script>

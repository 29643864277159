<template>
    <div class="">
        <h4 class="mb-2">
            {{ __('Set the output languages:') }}
        </h4>
        <p class="text-muted mb-0">
            {{
                __(
                    'We will translate your output columns for the selected languages'
                )
            }}
        </p>
        <div class="card card-flush mt-5">
            <!--start::Card body-->
            <div class="card-body p-7">
                <div class="d-flex align-items-center flex-wrap gap-3">
                    <ul class="d-flex flex-wrap gap-3 p-0 m-0">
                        <!-- Begin::Selected languages -->
                        <li
                            v-for="languageCode in bulkProcessingStore.selectedTranslations"
                            :key="languageCode"
                            class="list-unstyled px-3 py-2 border rounded-3 d-flex align-items-center min-h-40"
                        >
                            <img
                                :src="findLanguageByCode(languageCode)?.flag"
                                width="20"
                                height="15"
                                class="object-fit-cover"
                            />
                            <span class="mx-2">{{
                                findLanguageByCode(languageCode)?.name
                            }}</span>
                            <button
                                class="bg-transparent border-0 p-0 ms-auto"
                                @click="removeLanguage(languageCode)"
                            >
                                <i
                                    class="bi bi-x fs-2 text-black ms-auto position-relative"
                                ></i>
                            </button>
                        </li>
                        <!-- End::Selected languages -->
                        <!-- Begin::Language selection -->
                        <li class="list-unstyled">
                            <LanguageSelection
                                :defaultLanguage="userLanguage"
                                :languages="bulkProcessingStore.languages"
                                :notAllowed="
                                    bulkProcessingStore.selectedTranslations
                                "
                                @language-selected="languageSelected"
                            />
                        </li>
                        <!-- End::Language selection -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import LanguageSelection from '@/Pages/BulkProcessing/Partials/Create/LanguageSelection.vue';
import { useBulkProcessingStore } from '@/Stores/BulkProcessingStore';
import { useUserStore } from '@/Stores/UserStore';

export default defineComponent({
    name: 'BulkProcessingLanguageContainer',

    components: {
        LanguageSelection,
    },

    setup() {
        const bulkProcessingStore = useBulkProcessingStore();
        const userStore = useUserStore();

        return {
            bulkProcessingStore,
            userStore,
        };
    },

    data() {
        return {
            search: null,
            userLanguage: this.userStore.getUserInfo?.language || 'en',
            languageCache: {},
        };
    },

    mounted() {
        nextTick(() => {
            // Use nextTick to wait for DOM updates
            const languages = this.bulkProcessingStore?.languages;
            const vm = this;
            if (languages && languages.length) {
                const language = languages.find(
                    (language) => language.code === vm.userLanguage
                );
                this.addNewTranslation(language.code);
            }
        });
    },

    methods: {
        addNewTranslation(languageCode) {
            this.bulkProcessingStore.selectedTranslations.push(languageCode);
        },
        languageSelected(languageCode) {
            this.addNewTranslation(languageCode);
        },
        findLanguageByCode(languageCode) {
            if (this.languageCache[languageCode]) {
                return this.languageCache[languageCode];
            }

            const language = this.bulkProcessingStore?.languages?.find(
                (language) => language.code === languageCode
            );
            const name =
                language.name[this.userLanguage] ?? language.name['en'];

            const result = { ...language, ...{ name } };
            this.languageCache[languageCode] = result;

            return result;
        },
        removeLanguage(languageCode) {
            const index =
                this.bulkProcessingStore.selectedTranslations.indexOf(
                    languageCode
                );
            if (index > -1) {
                this.bulkProcessingStore.selectedTranslations.splice(index, 1);
            }
        },
    },
});
</script>

<style lang="scss">
.min-h-40 {
    min-height: 40px;
}
</style>

<template>
    <div
        class="single-output-item col-w-25 p-7 border border-start-0 border-top-0"
    >
        <div class="d-flex align-items-center mb-5">
            <!-- begin::Grip icon -->
            <div class="cursor-pointer fw-bolder me-3">
                <svg
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.5625 2.0625C9.5625 1.59766 9.80859 1.1875 10.2188 0.941406C10.6016 0.695312 11.1211 0.695312 11.5312 0.941406C11.9141 1.1875 12.1875 1.59766 12.1875 2.0625C12.1875 2.55469 11.9141 2.96484 11.5312 3.21094C11.1211 3.45703 10.6016 3.45703 10.2188 3.21094C9.80859 2.96484 9.5625 2.55469 9.5625 2.0625ZM5.1875 2.0625C5.1875 1.59766 5.43359 1.1875 5.84375 0.941406C6.22656 0.695312 6.74609 0.695312 7.15625 0.941406C7.53906 1.1875 7.8125 1.59766 7.8125 2.0625C7.8125 2.55469 7.53906 2.96484 7.15625 3.21094C6.74609 3.45703 6.22656 3.45703 5.84375 3.21094C5.43359 2.96484 5.1875 2.55469 5.1875 2.0625ZM2.125 3.375C1.63281 3.375 1.22266 3.12891 0.976562 2.71875C0.730469 2.33594 0.730469 1.81641 0.976562 1.40625C1.22266 1.02344 1.63281 0.75 2.125 0.75C2.58984 0.75 3 1.02344 3.24609 1.40625C3.49219 1.81641 3.49219 2.33594 3.24609 2.71875C3 3.12891 2.58984 3.375 2.125 3.375ZM9.5625 6.4375C9.5625 5.97266 9.80859 5.5625 10.2188 5.31641C10.6016 5.07031 11.1211 5.07031 11.5312 5.31641C11.9141 5.5625 12.1875 5.97266 12.1875 6.4375C12.1875 6.92969 11.9141 7.33984 11.5312 7.58594C11.1211 7.83203 10.6016 7.83203 10.2188 7.58594C9.80859 7.33984 9.5625 6.92969 9.5625 6.4375ZM6.5 7.75C6.00781 7.75 5.59766 7.50391 5.35156 7.09375C5.10547 6.71094 5.10547 6.19141 5.35156 5.78125C5.59766 5.39844 6.00781 5.125 6.5 5.125C6.96484 5.125 7.375 5.39844 7.62109 5.78125C7.86719 6.19141 7.86719 6.71094 7.62109 7.09375C7.375 7.50391 6.96484 7.75 6.5 7.75ZM0.8125 6.4375C0.8125 5.97266 1.05859 5.5625 1.46875 5.31641C1.85156 5.07031 2.37109 5.07031 2.78125 5.31641C3.16406 5.5625 3.4375 5.97266 3.4375 6.4375C3.4375 6.92969 3.16406 7.33984 2.78125 7.58594C2.37109 7.83203 1.85156 7.83203 1.46875 7.58594C1.05859 7.33984 0.8125 6.92969 0.8125 6.4375Z"
                        fill="black"
                    />
                </svg>
            </div>
            <!-- end::Grip icon -->
            <!-- begin::Enter column name -->
            <input
                type="text"
                v-model="output.column_name"
                class="border-0 p-0 column-name-input"
                :placeholder="__('Enter your column name')"
            />
            <!-- end::Enter column name -->
            <!-- begin::Delete button -->
            <button
                class="bg-transparent border-0 p-0 ms-auto"
                @click="$emit('remove', index)"
                v-if="bulkProcessingStore?.outputSelections?.length > 1"
            >
                <i class="bi bi-trash3 fs-6 text-black"></i>
            </button>
            <!-- end::Delete button -->
        </div>
        <label class="mb-1 fw-bolder">
            {{ __('Input columns') }}
            <!-- Begin::Tooltip -->
            <div
                class="custom-tooltip position-relative d-inline-flex align-items-center"
                role="tooltip"
                tabindex="0"
            >
                <i
                    class="bi bi-info-circle fs-6 text-dark fw-bolder ms-1"
                    aria-hidden="true"
                ></i>
                <span
                    class="custom-tooltip-text bg-white rounded px-3 py-2 position-absolute shadow-sm"
                    role="tooltip"
                >
                    {{
                        __(
                            'You can select multiple columns to be used as input for this column.'
                        )
                    }}
                </span>
            </div>
            <!-- End::Tooltip -->
        </label>
        <Multiselect
            v-model="output.inputs"
            mode="tags"
            :placeholder="__('Select the inputs columns')"
            track-by="name"
            label="name"
            :close-on-select="false"
            :searchable="true"
            :hideSelected="false"
            :options="bulkProcessingStore.fileHeaders"
            class="mb-5 p-0 py-1 rounded"
        >
            <template v-slot:option="{ option }">
                <div
                    class="fs-6 w-100 d-flex align-items-center justify-content-between"
                >
                    {{ option.name }}
                    <!-- Input columns option switch -->
                    <div class="option-switch">
                        <div class="switch-handle"></div>
                    </div>
                </div>
            </template>

            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                <div class="tag-wrapper">
                    <div
                        class="multiselect-tag"
                        :class="{
                            'is-disabled': disabled,
                        }"
                    >
                        {{ option.name }}
                        <span
                            v-if="!disabled"
                            class="multiselect-tag-remove"
                            @click="handleTagRemove(option, $event)"
                        >
                            <span class="multiselect-tag-remove-icon"></span>
                        </span>
                    </div>
                </div>
            </template>
        </Multiselect>

        <label class="mb-1 fw-bolder">
            {{ __('AI Asssistant') }}
            <!-- Begin::Tooltip -->
            <div
                class="custom-tooltip position-relative d-inline-flex align-items-center"
                role="tooltip"
                tabindex="0"
            >
                <i
                    class="bi bi-info-circle fs-6 text-dark fw-bolder ms-1"
                    aria-hidden="true"
                ></i>
                <span
                    class="custom-tooltip-text bg-white rounded px-3 py-2 position-absolute shadow-sm"
                    role="tooltip"
                >
                    {{
                        __(
                            'You can select an AI assistant to be used for this column.'
                        )
                    }}
                </span>
            </div>
            <!-- End::Tooltip -->
        </label>
        <Multiselect
            v-model="output.assistant"
            :placeholder="__('Select the AI Assistant')"
            track-by="name"
            label="name"
            :close-on-select="true"
            :searchable="true"
            :hideSelected="true"
            :options="aiAssistantTemplates"
            class="p-0 py-1 rounded"
        >
            <template v-slot:option="{ option }">
                <div
                    class="fs-6 w-100 position-relative d-flex align-items-center justify-content-between"
                >
                    {{ option.name }}
                    <!-- AI Assistant option radio button -->
                    <div
                        class="option-radio position-relative rounded-circle"
                    ></div>
                </div>
            </template>
            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                <div class="tag-wrapper">
                    <div
                        class="multiselect-tag"
                        :class="{
                            'is-disabled': disabled,
                        }"
                    >
                        {{ option.name }}
                        <span
                            v-if="!disabled"
                            class="multiselect-tag-remove"
                            @click="handleTagRemove(option, $event)"
                        >
                            <span class="multiselect-tag-remove-icon"></span>
                        </span>
                    </div>
                </div>
            </template>
        </Multiselect>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useBulkProcessingStore } from '@/Stores/BulkProcessingStore';
import { useUserStore } from '@/Stores/UserStore';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
    name: 'BulkProcessingOutputItem',
    props: {
        output: {
            type: Object,
            default: () => ({}),
        },
        index: {
            type: Number,
            required: true,
        },
    },

    components: {
        Multiselect,
    },

    setup() {
        const bulkProcessingStore = useBulkProcessingStore();
        const userStore = useUserStore();

        return {
            bulkProcessingStore,
            userStore,
        };
    },

    watch: {
        output: {
            deep: true,
            handler(newOutput) {
                this.$emit('update:output', newOutput);
            },
        },
    },

    computed: {
        aiAssistantTemplates() {
            try {
                const userLanguage =
                    this.userStore.getUserInfo?.language || 'en';
                return this.bulkProcessingStore.aiAssistantTemplates?.map(
                    (template) => ({
                        name:
                            template.name[userLanguage] || template.name['en'],
                        value: template.uuid,
                    })
                );
            } catch (error) {
                console.error(error);
            }

            return [];
        },
    },
});
</script>

<style lang="scss">
// Multiselect
.multiselect {
    &.is-active {
        box-shadow: none !important;
    }

    .multiselect-search {
        border-radius: 12px !important;
    }

    .multiselect-dropdown {
        border-radius: 12px !important;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        bottom: -5px !important;

        .multiselect-option {
            padding: 8px 12px;
            background-color: transparent !important;
            color: #181c32 !important;

            // AI Assistant option radio button
            .option-radio {
                width: 20px;
                height: 20px;
                background-color: #d9dbdf;

                &:after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: #fff;
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.2s ease-in-out;
                }
            }
            &.is-selected {
                .option-radio {
                    background-color: #1da8f7;

                    &::after {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            // Input columns option switch
            .option-switch {
                width: 36px;
                height: 20px;
                background-color: #d9dbdf;
                border-radius: 12px;
                position: relative;
                transition: background-color 0.2s ease-in-out;

                .switch-handle {
                    width: 16px;
                    height: 16px;
                    background-color: white;
                    border-radius: 50%;
                    position: absolute;
                    left: 2px;
                    top: 2px;
                    transition: transform 0.2s ease-in-out;
                }
            }

            &.is-selected {
                .option-switch {
                    background-color: #1da8f7;

                    .switch-handle {
                        transform: translateX(16px);
                    }
                }
            }
        }
    }
    .multiselect-tags {
        margin-top: 0;

        .tag-wrapper {
            div {
                background-color: #e7edff;
                color: #101d36;
                padding: 5px 6px;
                margin: 3px;
            }
        }
    }
}

.column-name-input {
    outline: none;
}

// A cutom class for the width of the column
.col-w-25 {
    width: 25%;

    @media (max-width: 1200px) {
        width: 50%;
    }
}

// Tooltip
.custom-tooltip {
    .custom-tooltip-text {
        visibility: hidden;
        min-width: 200px;
        max-width: 300px;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.4;
        color: #101d36;
        z-index: 1080;
        top: calc(100% + 5px);
        left: 0;
        opacity: 0;
        transition: all 0.2s ease-in-out;
    }
    &:hover,
    &:focus,
    &:focus-within {
        .custom-tooltip-text {
            visibility: visible;
            opacity: 1;
        }
    }
}
</style>

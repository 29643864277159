<template>
    <!--begin::Modal-->
    <Teleport to="body">
        <div
            class="modal fade"
            id="bulk-processing-output-preview-modal"
            tabindex="-1"
            aria-hidden="true"
            data-bs-backdrop="static"
        >
            <!--begin::Modal dialog-->
            <div class="modal-dialog modal-dialog-centered modal-xl">
                <!--begin::Modal content-->
                <div class="modal-content modal-rounded p-8">
                    <div v-if="isGenerating" class="text-center">
                        <div class="modal-icon m-auto mb-7">
                            <svg
                                width="56"
                                height="56"
                                viewBox="0 0 56 56"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    opacity="0.4"
                                    d="M0 27.9945C0 12.5647 12.4797 0.0546768 27.9016 0C26.0203 0.0546768 24.5 1.59656 24.5 3.49932C24.5 5.43488 26.0641 6.99863 28 6.99863C16.4063 6.99863 7 16.403 7 27.9945C7 39.586 16.4063 48.9904 28 48.9904C35.6234 48.9904 42.2953 44.9334 45.9922 38.8643C45.6641 39.586 45.6313 40.4827 45.8828 41.2919C46.0141 41.6965 46.2109 42.0793 46.4734 42.4183C46.6047 42.5823 46.7578 42.7463 46.9219 42.8885C47.0094 42.965 47.0969 43.0307 47.1844 43.0963C47.2281 43.1291 47.2719 43.1619 47.3266 43.1947C47.3813 43.2275 47.425 43.2603 47.4688 43.2822C48.0156 43.5993 48.6281 43.7524 49.2188 43.7524C50.4219 43.7524 51.6031 43.1291 52.2484 42.0027C47.4031 50.3683 38.3578 56 28 56C12.5344 55.9891 0 43.4571 0 27.9945Z"
                                    fill="#0186D2"
                                />
                                <path
                                    d="M24.5 3.49932C24.5 1.56376 26.0641 0 28 0C43.4656 0 56 12.5319 56 27.9945C56 33.0904 54.6328 37.8801 52.2484 41.9918C51.2859 43.6649 49.1422 44.2336 47.4688 43.2712C45.7953 42.3089 45.2266 40.1656 46.1891 38.4925C47.9719 35.4087 49 31.8219 49 27.9945C49 16.403 39.5938 6.99863 28 6.99863C26.0641 6.99863 24.5 5.43488 24.5 3.49932Z"
                                    fill="#0186D2"
                                />
                            </svg>
                        </div>
                        <h4 class="mb-4">
                            {{ __('Generating Output Preview') }}
                        </h4>
                        <p class="text-secondary-emphasis fs-5 mb-0">
                            {{
                                __(
                                    "This may take a few moments. Please stay on this page until it's complete."
                                )
                            }}
                        </p>
                    </div>
                    <div v-else-if="isGenerated">
                        <h6 class="mb-5">
                            {{
                                __(
                                    "Review the generated content for the output columns. If it's good, go and create the document."
                                )
                            }}
                        </h6>
                        <div
                            class="badge-info p-4 mb-5 rounded-3 d-flex align-items-center"
                        >
                            <i class="bi bi-info-circle me-2"></i>
                            {{
                                __(
                                    'These are just the first five lines of your table. You going to be able to see it all when you export it. '
                                )
                            }}
                        </div>
                        <div class="table-responsive border rounded-3 p-4">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th
                                            v-for="header in previewHeaders"
                                            :key="header"
                                            class="text-uppercase text-muted"
                                        >
                                            <div
                                                v-if="
                                                    isAIGeneratedColumn(header)
                                                "
                                                class="d-flex align-items-center"
                                            >
                                                <svg
                                                    width="14"
                                                    height="15"
                                                    viewBox="0 0 14 15"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="me-2"
                                                >
                                                    <path
                                                        d="M8.94141 3.10156L10.5 2.5L11.0742 0.96875C11.1016 0.832031 11.2383 0.75 11.375 0.75C11.4844 0.75 11.6211 0.832031 11.6484 0.96875L12.25 2.5L13.7812 3.10156C13.918 3.12891 14 3.26562 14 3.375C14 3.51172 13.918 3.64844 13.7812 3.67578L12.25 4.25L11.6484 5.80859C11.6211 5.91797 11.4844 6 11.375 6C11.2383 6 11.1016 5.91797 11.0742 5.80859L10.5 4.25L8.94141 3.67578C8.83203 3.64844 8.75 3.51172 8.75 3.375C8.75 3.26562 8.83203 3.12891 8.94141 3.10156ZM5.60547 2.77344L7.02734 5.89062L10.1445 7.3125C10.3086 7.39453 10.418 7.55859 10.418 7.72266C10.418 7.88672 10.3086 8.05078 10.1445 8.10547L7.02734 9.55469L5.60547 12.6719C5.52344 12.8359 5.35938 12.9453 5.19531 12.9453C5.03125 12.9453 4.86719 12.8359 4.8125 12.6719L3.36328 9.55469L0.246094 8.13281C0.0820312 8.05078 0 7.88672 0 7.72266C0 7.55859 0.0820312 7.39453 0.246094 7.3125L3.36328 5.89062L4.8125 2.77344C4.86719 2.60938 5.03125 2.5 5.19531 2.5C5.35938 2.5 5.52344 2.60938 5.60547 2.77344ZM10.5 11.25L11.0742 9.71875C11.1016 9.58203 11.2383 9.5 11.375 9.5C11.4844 9.5 11.6211 9.58203 11.6484 9.71875L12.25 11.25L13.7812 11.8516C13.918 11.8789 14 12.0156 14 12.125C14 12.2617 13.918 12.3984 13.7812 12.4258L12.25 13L11.6484 14.5586C11.6211 14.668 11.4844 14.75 11.375 14.75C11.2383 14.75 11.1016 14.668 11.0742 14.5586L10.5 13L8.94141 12.4258C8.83203 12.3984 8.75 12.2617 8.75 12.125C8.75 12.0156 8.83203 11.8789 8.94141 11.8516L10.5 11.25Z"
                                                        fill="#3B68FF"
                                                    />
                                                </svg>
                                                {{ header }}
                                                <div
                                                    class="custom-tooltip position-relative d-inline-flex align-items-center ms-2"
                                                    role="tooltip"
                                                    tabindex="0"
                                                >
                                                    <i
                                                        class="bi bi-info-circle fs-6 text-dark fw-bolder ms-1"
                                                        aria-hidden="true"
                                                    ></i>
                                                    <span
                                                        class="custom-tooltip-text bg-white rounded px-3 py-2 position-absolute shadow-sm"
                                                        role="tooltip"
                                                    >
                                                        {{
                                                            __(
                                                                'This column is generated by AI. You can select multiple columns to be used as input for this column.'
                                                            )
                                                        }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div v-else>
                                                {{ header }}
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(data, index) in previewData"
                                        :key="index"
                                    >
                                        <td
                                            v-for="(
                                                header, index
                                            ) in previewHeaders"
                                            :key="`${header}-${index}`"
                                            class="border-bottom"
                                        >
                                            {{ data[header] }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="d-flex gap-4 mt-4">
                            <button
                                class="btn btn-secondary w-50"
                                @click="returnToBulkSettings"
                            >
                                {{ __('Return to bulk settings') }}
                            </button>
                            <button
                                class="btn btn-primary w-50"
                                @click="createDocument"
                            >
                                {{ __('Create Document') }}
                            </button>
                        </div>
                    </div>

                    <div v-else-if="isCreating" class="text-center">
                        <div class="modal-icon m-auto mb-7">
                            <svg
                                width="80"
                                height="80"
                                viewBox="0 0 80 80"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    opacity="0.1"
                                    cx="40"
                                    cy="40"
                                    r="40"
                                    fill="#1DA8F7"
                                />
                                <g clip-path="url(#clip0_178_4558)">
                                    <path
                                        opacity="0.4"
                                        d="M24 33C24 33.225 24.15 33.4187 24.3625 33.4812L27.5938 34.4062L28.5188 37.6375C28.5813 37.85 28.775 38 29 38C29.225 38 29.4187 37.85 29.4812 37.6375L30.4062 34.4062L33.6375 33.4812C33.85 33.4187 34 33.225 34 33C34 32.775 33.85 32.5813 33.6375 32.5188L30.4062 31.5938L29.4812 28.3625C29.4187 28.15 29.225 28 29 28C28.775 28 28.5813 28.15 28.5188 28.3625L27.5938 31.5938L24.3625 32.5188C24.15 32.5813 24 32.775 24 33ZM34 27C34 27.2313 34.1562 27.4312 34.3813 27.4875L36.0875 27.9125L36.5125 29.6188C36.5687 29.8438 36.7688 30 37 30C37.2312 30 37.4313 29.8438 37.4875 29.6188L37.9125 27.9125L39.6187 27.4875C39.8438 27.4312 40 27.2313 40 27C40 26.7687 39.8438 26.5688 39.6187 26.5125L37.9125 26.0875L37.4875 24.3812C37.4313 24.1562 37.2312 24 37 24C36.7688 24 36.5687 24.1562 36.5125 24.3812L36.0875 26.0875L34.3813 26.5125C34.1562 26.5688 34 26.7687 34 27ZM46 47C46 47.225 46.15 47.4188 46.3625 47.4813L49.5938 48.4062L50.5187 51.6375C50.5812 51.85 50.775 52 51 52C51.225 52 51.4188 51.85 51.4813 51.6375L52.4062 48.4062L55.6375 47.4813C55.85 47.4188 56 47.225 56 47C56 46.775 55.85 46.5812 55.6375 46.5187L52.4062 45.5938L51.4813 42.3625C51.4188 42.15 51.225 42 51 42C50.775 42 50.5812 42.15 50.5187 42.3625L49.5938 45.5938L46.3625 46.5187C46.15 46.5812 46 46.775 46 47Z"
                                        fill="#0186D2"
                                    />
                                    <path
                                        d="M55.0625 24.4374C54.5 23.8749 53.5938 23.8499 53 24.3811L35.8313 39.7436C35.65 39.9061 35.4125 39.9999 35.1625 39.9999H33C32.45 39.9999 32 40.4499 32 40.9999V42.7249C32 43.0124 31.8812 43.2811 31.6687 43.4686L24.6938 49.7061C24.25 50.1061 24 50.6686 24 51.2624C24 51.8124 24.2188 52.3436 24.6062 52.7311L27.2687 55.3936C27.6562 55.7811 28.1875 56.0061 28.7375 56.0061C29.3313 56.0061 29.8937 55.7561 30.2875 55.3124L47.2687 36.3311C47.4562 36.1186 47.7313 35.9999 48.0125 35.9999H49C49.55 35.9999 50 35.5499 50 34.9999V33.6624C50 33.4186 50.0938 33.1811 50.2563 32.9936L55.6187 26.9999C56.15 26.4061 56.125 25.4999 55.5625 24.9374L55.0625 24.4374Z"
                                        fill="#0186D2"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_178_4558">
                                        <rect
                                            width="32"
                                            height="32"
                                            fill="white"
                                            transform="translate(24 24)"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <h4 class="mb-4">
                            {{ __("We're working on the final content") }}
                        </h4>
                        <p class="text-secondary-emphasis fs-5 mb-7">
                            {{
                                __(
                                    "Don't worry, you can continually using the tool. We gonna notify you when the file is ready."
                                )
                            }}
                        </p>
                        <button class="btn btn-primary w-100">
                            {{ __('Continue to the app') }}
                        </button>
                    </div>
                </div>
                <!--end::Modal content-->
            </div>
            <!--end::Modal dialog-->
        </div>
    </Teleport>
</template>

<script>
import { Modal } from 'bootstrap';
import { useBulkProcessingStore } from '@/Stores/BulkProcessingStore';
import { usePage } from '@inertiajs/vue3';
import { useToast } from 'vue-toastification';
import { router } from '@inertiajs/vue3';

export default {
    setup() {
        const bulkProcessingStore = useBulkProcessingStore();
        const page = usePage();
        const toast = useToast();

        return {
            bulkProcessingStore,
            page,
            toast,
        };
    },

    data() {
        return {
            modal: {},
            isCreating: false,
            isGenerating: true,
            isGenerated: false,
            previewData: [],
            previewHeaders: [],
        };
    },
    mounted() {
        let vm = this;

        this.modal = new Modal(
            document.getElementById('bulk-processing-output-preview-modal')
        );
        // eslint-disable-next-line no-undef
        emitter.on('open-bulk-processing-output-preview-modal', () => {
            vm.openModal();
            vm.startProcessingForPreview();
        });
        // eslint-disable-next-line no-undef
        emitter.on('close-bulk-processing-output-preview-modal', () =>
            vm.closeModal()
        );
    },

    beforeUnmount() {
        // eslint-disable-next-line no-undef
        emitter.off('open-bulk-processing-output-preview-modal');
        // eslint-disable-next-line no-undef
        emitter.off('close-bulk-processing-output-preview-modal');
    },

    methods: {
        openModal() {
            this.modal.show();
        },
        closeModal() {
            this.modal.hide();
        },
        startProcessingForPreview() {
            const vm = this;
            const payload = this.getPayload({ forPreview: true });

            // eslint-disable-next-line no-undef
            axios
                // eslint-disable-next-line no-undef
                .post(route('bulk-processings.store'), payload)
                .then((response) => {
                    const previewData = response.data.preview_data;
                    if (0 === previewData.length) {
                        return false;
                    }
                    vm.previewData = previewData;
                    vm.previewHeaders = Object.keys(previewData[0]);
                    vm.isGenerating = false;
                    vm.isGenerated = true;
                })
                .catch((error) => {
                    let message =
                        error?.response?.data?.message ||
                        vm.__('Some error occurred');
                    const errors = error?.response?.data?.errors;

                    if (errors) {
                        // Create an array of error messages
                        const errorMessages = Object.values(errors).flat();
                        // Join them with a newline character
                        message = errorMessages.join('\n');
                    }

                    vm.toast.error(message);
                    vm.closeModal();
                    vm.resetData();
                });
        },
        isAIGeneratedColumn(header) {
            header = header.trim(); // Trim the header to remove whitespace

            return !this.bulkProcessingStore.fileHeaders.includes(header);
        },
        returnToBulkSettings() {
            this.closeModal();
            this.resetData();
        },
        resetData() {
            this.isCreating = false;
            this.isGenerating = true;
            this.isGenerated = false;
            this.previewData = [];
            this.previewHeaders = [];
        },
        createDocument() {
            const vm = this;
            const payload = this.getPayload({ forPreview: false });
            vm.isGenerated = false;
            vm.isCreating = true;
            // eslint-disable-next-line no-undef
            axios
                // eslint-disable-next-line no-undef
                .post(route('bulk-processings.store'), payload)
                .then((response) => {
                    const message = response.data.message;
                    vm.toast.success(message);
                    vm.closeModal();
                    vm.resetData();
                    vm.bulkProcessingStore.resetStore();
                    // eslint-disable-next-line no-undef
                    router.visit(route('bulk-processings.index'));
                })
                .catch((error) => {
                    let message =
                        error?.response?.data?.message ||
                        vm.__('Some error occurred');
                    const errors = error?.response?.data?.errors;

                    if (errors) {
                        // Create an array of error messages
                        const errorMessages = Object.values(errors).flat();
                        // Join them with a newline character
                        message += '\n' + errorMessages.join('\n');
                    }

                    vm.toast.error(message);
                });
        },
        getPayload({ forPreview }) {
            return {
                file_headers: this.bulkProcessingStore.fileHeaders,
                is_on_preview: forPreview,
                output_selections: this.bulkProcessingStore.outputSelections,
                server_id: this.page.props.server_id,
                translations: this.bulkProcessingStore.selectedTranslations,
            };
        },
    },
};
</script>

<style scoped lang="scss">
.badge-info {
    background-color: #e6f3fa;
    color: #004085;

    i {
        color: #3b68ff;
    }
}
// Tooltip
.custom-tooltip {
    .custom-tooltip-text {
        visibility: hidden;
        min-width: 200px;
        max-width: 300px;
        font-weight: normal;
        text-transform: none;
        font-size: 12px;
        line-height: 1.4;
        color: #101d36;
        z-index: 1080;
        top: calc(100% + 5px);
        right: 0;
        opacity: 0;
        transition: all 0.2s ease-in-out;
    }
    &:hover,
    &:focus,
    &:focus-within {
        .custom-tooltip-text {
            visibility: visible;
            opacity: 1;
        }
    }
}
</style>

<template>
    <div class="dropdown">
        <!-- Begin::Add language button -->
        <button
            class="btn dropdown-toggle px-3 py-2 border rounded-3 text-primary border-primary border-dashed min-h-40 add-language-button"
            type="button"
            id="languageTrigger"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        >
            <i class="bi bi-plus me-2 p-0 fs-2 text-primary fw-bolder"></i>
            <slot>{{ __('Add language') }}</slot>
        </button>
        <!-- End::Add language button -->
        <!-- Begin::Language options -->
        <ul
            class="dropdown-menu w-auto bg-white"
            aria-labelledby="languageTrigger"
            style="min-width: 15rem"
        >
            <li class="dropdown-item">
                <input
                    type="text"
                    v-model="search"
                    class="form-control"
                    placeholder="Search language"
                />
            </li>
            <li
                class="dropdown-item"
                v-for="language in languageOptions"
                :key="language.code"
                @click="languageSelected(language.code)"
            >
                <img
                    :src="language.flag"
                    width="20"
                    height="15"
                    class="object-fit-cover ms-2"
                />
                {{ language.name[defaultLanguage] ?? language.name['en'] }}
            </li>
        </ul>
        <!-- End::Language options -->
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'LanguageSelection',

    props: {
        defaultLanguage: {
            type: String,
            default: 'en',
        },
        languages: {
            type: Array,
            default: () => [],
        },
        notAllowed: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            search: null,
        };
    },

    computed: {
        languageOptions() {
            const vm = this;
            return (
                this.languages?.filter((language) => {
                    if (vm.notAllowed.includes(language.code)) {
                        return false;
                    }
                    if (!vm.search) {
                        return true;
                    }
                    return language.name[vm.defaultLanguage]
                        ?.toLowerCase()
                        .includes(this.search?.toLowerCase());
                }) || []
            );
        },
    },

    methods: {
        languageSelected(languageCode) {
            this.$emit('language-selected', languageCode);
            this.search = null;
        },
    },
});
</script>

<style lang="scss">
.dropdown-toggle.add-language-button {
    &::after {
        display: none;
    }
}
</style>

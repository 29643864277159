<template>
    <div class="mb-10">
        <h4 class="mb-2">
            {{ __('Create or select your output columns:') }}
        </h4>
        <p class="text-muted mb-0">
            {{
                __(
                    'These columns will receive generated content based on the inputs and AI assistant selected'
                )
            }}
        </p>
        <div class="card card-flush mt-5">
            <!--start::Card body-->
            <div class="card-body p-0">
                <draggable
                    class="d-flex flex-wrap"
                    :list="bulkProcessingStore.outputSelections"
                    @change="log"
                >
                    <OutputItem
                        v-for="(
                            output, index
                        ) in bulkProcessingStore.outputSelections"
                        v-model:output="
                            bulkProcessingStore.outputSelections[index]
                        "
                        :key="index"
                        :index="index"
                        class="flex-shrink-0"
                        @remove="removeOutputWarning"
                    />
                </draggable>
                <!-- begin::Add new output column -->
                <div
                    class="d-flex justify-content-center flex-shrink-0 col-w-25 p-7 border border-start-0 border-top-0 add-output-column"
                >
                    <button
                        class="p-0 bg-transparent border-0"
                        @click="addNewOutputItem"
                    >
                        <i class="bi bi-plus-lg text-primary fs-2"></i>
                        <span class="fw-bolder d-block">
                            {{ __('Add new output column') }}
                        </span>
                    </button>
                </div>
                <!-- end::Add new output column -->
            </div>
        </div>
        <OutputRemoveModal @delete-column="removeOutput" />
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import OutputItem from '@/Pages/BulkProcessing/Partials/Create/OutputItem.vue';
import OutputRemoveModal from '@/Pages/BulkProcessing/Partials/Modals/OutputRemoveModal.vue';
import { useBulkProcessingStore } from '@/Stores/BulkProcessingStore';
import { VueDraggableNext } from 'vue-draggable-next';

export default defineComponent({
    name: 'BulkProcessingOutputContainer',

    components: {
        OutputItem,
        OutputRemoveModal,
        draggable: VueDraggableNext,
    },

    setup() {
        const bulkProcessingStore = useBulkProcessingStore();

        return {
            bulkProcessingStore,
        };
    },

    data() {
        return {
            removeIndex: null,
        };
    },

    mounted() {
        this.addNewOutputItem();
    },

    methods: {
        addNewOutputItem() {
            this.bulkProcessingStore.outputSelections.push({
                column_name: '',
                assistant: null,
                inputs: [],
            });
        },
        removeOutputWarning(index) {
            this.removeIndex = index;
            // eslint-disable-next-line no-undef
            emitter.emit('open-bulk-processing-output-remove-warning-modal');
        },
        removeOutput() {
            this.bulkProcessingStore.outputSelections.splice(
                this.removeIndex,
                1
            );
        },
        log() {
            // Define the log method to avoid the error
            console.log('Draggable list changed');
        },
    },
});
</script>

<style lang="scss">
.add-output-column {
    min-height: 230px;
    button {
        transition: color 0.3s ease;

        &:hover {
            color: #0186d2;
        }
    }
}
</style>

<template>
    <div class="skeleton-loader">
        <div class="skeleton-loader-item small w-25"></div>
        <div class="skeleton-loader-item w-100"></div>
        <div class="skeleton-loader-item small w-25"></div>
        <div class="skeleton-loader-item medium w-100"></div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'BulkProcessingListingSkeleton',
});
</script>

<style lang="scss">
.skeleton-loader {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .skeleton-loader-item {
        height: 300px;
        border-radius: 8px;
        animation-duration: 1.7s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: placeholderAnimate;
        background: linear-gradient(to right, #eee 2%, #ddd 18%, #eee 33%);
        background-size: 1300px;

        &.small {
            height: 20px;
        }

        &.medium {
            height: 150px;
        }
    }
}

@keyframes placeholderAnimate {
    0% {
        background-position: -650px 0;
    }
    100% {
        background-position: 650px 0;
    }
}
</style>

<template>
    <app-layout
        :title="__('Bulk Processing')"
        :view="__('Generate in Bulk')"
        :user="$attrs.user"
    >
        <FileMeta :fileDetails="fileDetails" />
        <OutputContainer />
        <LanguageContainer />
        <OutputPreviewModal />
        <div class="text-end">
            <button
                class="btn mt-10"
                :class="[
                    bulkProcessingStore.isValidSubmission
                        ? 'btn-primary'
                        : 'btn-secondary',
                ]"
                @click="startBulkProcessing"
                :disabled="!bulkProcessingStore.isValidSubmission"
            >
                {{ __('Continue') }}
            </button>
        </div>
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import FileMeta from '@/Pages/BulkProcessing/Partials/Create/FileMeta.vue';
import OutputContainer from '@/Pages/BulkProcessing/Partials/Create/OutputContainer.vue';
import LanguageContainer from '@/Pages/BulkProcessing/Partials/Create/LanguageContainer.vue';
import OutputPreviewModal from '@/Pages/BulkProcessing/Partials/Modals/OutputPreviewModal.vue';
import { usePage } from '@inertiajs/vue3';
import { useBulkProcessingStore } from '@/Stores/BulkProcessingStore';

export default defineComponent({
    name: 'BulkProcessingCreate',
    components: {
        AppLayout,
        FileMeta,
        OutputContainer,
        OutputPreviewModal,
        LanguageContainer,
    },

    setup() {
        const bulkProcessingStore = useBulkProcessingStore();
        const page = usePage();

        return {
            bulkProcessingStore,
            page,
        };
    },

    mounted() {
        this.bulkProcessingStore.fileHeaders = this.fileDetails.headers;
        this.bulkProcessingStore.languages = this.page.props.languages;
        this.bulkProcessingStore.aiAssistantTemplates =
            this.page.props.ai_templates;
    },

    computed: {
        fileDetails() {
            return this.page.props.file_details;
        },
    },

    methods: {
        startBulkProcessing() {
            // eslint-disable-next-line no-undef
            emitter.emit('open-bulk-processing-output-preview-modal');
        },
    },
});
</script>

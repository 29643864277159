<template>
    <app-layout
        :title="__('Bulk processing')"
        :view="__('Files')"
        :user="$attrs.user"
    >
        <template #header>
            <h1 class="h4 font-weight-bold">
                {{ __('Bulk processing') }}
            </h1>
        </template>
        <div class="row gx-5 gx-xl-10 mb-10">
            <div class="col-12">
                <h1>{{ __('Bulk processing') }}</h1>
                <div class="text-muted fs-7 fw-bold">
                    {{
                        __('Here you can find all the files you have created.')
                    }}
                </div>
            </div>
        </div>
        <BulkFilesTable />
    </app-layout>
</template>

<script>
import { defineComponent } from 'vue';
import AppLayout from '@/Layouts/AppLayout.vue';
import BulkFilesTable from '@/Components/Bulk/BulkFilesTable.vue';
import {directive} from 'vue3-click-away';
import moment from 'moment';

export default defineComponent({
    components: {
        AppLayout,
        BulkFilesTable,
    },

    directives: {
        ClickAway: directive,
    },

    data() {
        return {
            search: '',
            filesUrl: '/api/bulk-processings/old',// TODO::CRAI-527 remove this
            files: {},
            copyClicked: false,
            currentPage: 1,
            firstPageUrl: '',
            from: 1,
            to: 1,
            total: 1,
            lastPage: 1,
            lastPageUrl: '',
            nextPageUrl: '',
            prevPageUrl: '',
            pageLinks: {},
            selected: [],
            allSelected: false,
            fileIds: [],
            currentMenu: {
                open: false,
                element: {},
            },
            moment,
            inProgress: false,
            progressInterval: null,
        };
    },

    mounted() {
        this.fetchFiles(this.filesUrl);
        this.checkProgress();

        let vm = this;
        emitter.on('new-bulk-processing', function () {
            vm.fetchFiles(vm.filesUrl);
            vm.checkProgress();
        });
    },

    beforeUnmount() {
        emitter.off('new-bulk-processing');
        clearInterval(this.progressInterval);
    },

    methods: {
        checkProgress() {
            let vm = this;

            clearInterval(this.progressInterval);
            vm.progressInterval = setInterval(function () {
                vm.fetchFiles(vm.filesUrl);
                vm.files.forEach(function (file) {
                    if (file.progress < 100) {
                        vm.inProgress = true;
                    }
                });
                if (!vm.inProgress) {
                    clearInterval(vm.progressInterval);
                }
                vm.inProgress = false;
            }, 5000);
        },

        sendDataToModal(uuid) {
            emitter.emit('set-delete-candidate', {
                uuid: uuid,
                url: this.filesUrl,
                title: this.__('Are you sure?'),
                description: this.__(
                    'Do you really want to delete this file? This procedure is irreversible.'
                ),
                callback: this.deleteCallback,
            });
            this.$page.props.deleteCandidate = uuid;
        },

        sendBulkDataToModal() {
            emitter.emit('set-delete-candidate', {
                ids: this.fileIds,
                url: this.filesUrl,
                title: this.__('Are you sure?'),
                description: this.__(
                    'Do you really want to delete this file? This procedure is irreversible.'
                ),
                callback: this.deleteCallback,
            });
        },

        deleteCallback(uuid) {
            this.fetchFiles(this.filesUrl);
        },

        fetchFiles(url) {
            if (url) {
                let filterParams = 'search=' + this.search;
                let requestUrl = url.includes('page')
                    ? url + '&' + filterParams
                    : url + '?' + filterParams;

                axios.get(requestUrl).then((response) => {
                    this.files = response.data.data;
                    this.currentPage = response.data.current_page;
                    this.firstPageUrl = response.data.first_page_url;
                    this.from = response.data.from;
                    this.to = response.data.to;
                    this.total = response.data.total;
                    this.lastPage = response.data.last_page;
                    this.lastPageUrl = response.data.last_page_url;
                    this.nextPageUrl = response.data.next_page_url;
                    this.prevPageUrl = response.data.prev_page_url;
                    this.pageLinks = response.data.links;
                });
            }
        },

        onChangeSearch(ev) {
            this.fetchFiles(this.filesUrl);
        },

        selectAll() {
            let vm = this;
            vm.fileIds = [];
            if (this.allSelected) {
                this.files.forEach(function (file) {
                    vm.fileIds.push(file.uuid);
                });
            }
        },

        select() {
            this.allSelected = false;
        },

        toggle($event) {
            let el = $event.target.closest('.action-dropdown-menu');

            let s = el.classList;
            let m =
                el.parentElement.getElementsByClassName('menu-sub-dropdown')[0]
                    .classList;

            s.contains('show') ? s.remove('show') : s.add('show');
            s.contains('menu-dropdown')
                ? s.remove('menu-dropdown')
                : s.add('menu-dropdown');
            m.contains('show') ? m.remove('show') : m.add('show');
            this.currentMenu.open = this.currentMenu.open !== true;
            this.currentMenu.element = el;
        },

        onClickAway(ev) {
            if (this.currentMenu.open) {
                let el = this.currentMenu.element;

                if (!el.parentElement.contains(ev.target)) {
                    let s = el.classList;
                    let m =
                        el.parentElement.getElementsByClassName(
                            'menu-sub-dropdown'
                        )[0].classList;

                    s.contains('show') ? s.remove('show') : s.add('show');
                    s.contains('menu-dropdown')
                        ? s.remove('menu-dropdown')
                        : s.add('menu-dropdown');
                    m.contains('show') ? m.remove('show') : m.add('show');
                    this.currentMenu.open = false;
                }
            }
        },
    },
    computed: {},
});
</script>
